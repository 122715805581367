import PreviewRoutes from '@/pages/lines/preview/PreviewRoutes'
import ProductMappingRoutes from '@/pages/lines/product-mapping/ProductMappingRoutes'

const ProductMappingWrapper = () => import('@/pages/lines/product-mapping/ProductMappingWrapper')
const ProductExports = () => import('@/pages/lines/product-exports/ProductExports')
const LineManage = () => import('@/pages/lines/LineManage')
const LineSearch = () => import('@/pages/lines/LineSearch')
const Line = () => import('@/pages/lines/Line')
const LineOverview = () => import('@/pages/lines/LineOverview')
const Product = () => import('@/pages/lines/Product')
const ProductOverview = () => import('@/pages/lines/ProductOverview')
const RiskType = () => import('@/pages/lines/RiskType')
const RiskTypeDetails = () => import('@/pages/lines/RiskTypeDetails')
const RiskFieldDetails = () => import('@/pages/lines/RiskFieldDetails')
const RateTableDetails = () => import('@/pages/lines/RateTableDetails')
const RiskItemDetails = () => import('@/pages/lines/RiskItemDetails')
const RiskItemManage = () => import('@/pages/lines/RiskItemManage')
const ProductEdit = () => import('@/pages/lines/ProductEdit')
const SequenceDetails = () => import('@/pages/lines/SequenceDetails')
const Limit = () => import('@/pages/lines/Limit')

export default [
  {
    path: '',
    name: 'lines:search-lines',
    component: LineSearch
  },
  {
    path: 'create/',
    name: 'lines:create-line',
    component: LineManage,
    meta: {
      layout: 'full-page'
    }
  },
  {
    path: ':lineName',
    component: Line,
    meta: {
      breadcrumbsLoader: 'loadLineBreadcrumbs'
    },
    children: [
      {
        path: '',
        name: 'lines:line-overview',
        component: LineOverview
      },
      {
        path: 'edit',
        name: 'lines:edit-line',
        component: LineManage,
        props: true
      },
      {
        path: `product/:productName`,
        component: Product,
        meta: {
          breadcrumbsLoader: 'loadProductBreadcrumbs'
        },
        children: [
          {
            path: '',
            name: 'lines:product-overview',
            component: ProductOverview,
            props: true
          },
          {
            path: 'edit',
            name: 'lines:product-edit',
            component: ProductEdit,
            meta: {
              breadcrumbsLoader: 'loadProductEditBreadcrumbs'
            }
          },
          {
            path: 'sequence/',
            name: 'lines:add-sequence',
            component: SequenceDetails,
            props: true,
            meta: {
              breadcrumbsLoader: 'loadNewSequenceBreadcrumbs'
            }
          },
          {
            path: 'sequence/:sequenceName',
            name: 'lines:edit-sequence',
            component: SequenceDetails,
            props: true,
            meta: {
              breadcrumbsLoader: 'loadSequenceBreadcrumbs'
            }
          },
          {
            path: `risk-type/:riskTypeName`,
            component: RiskType,
            meta: {
              breadcrumbsLoader: 'loadRiskTypeBreadcrumbs'
            },
            children: [
              {
                path: '',
                name: 'lines:risk-type-details',
                component: RiskTypeDetails
              },
              {
                path: 'field',
                name: 'lines:add-risk-field',
                component: RiskFieldDetails,
                props: (route) => ({ ...route.params, ...route.query }),
                meta: {
                  layout: 'full-page',
                  breadcrumbsLoader: 'loadNewRiskFieldBreadcrumbs'
                }
              },
              {
                path: 'field/:riskFieldName',
                name: 'lines:update-risk-field',
                component: RiskFieldDetails,
                props: true,
                meta: {
                  layout: 'full-page',
                  breadcrumbsLoader: 'loadRiskFieldBreadcrumbs'
                }
              },
              {
                path: 'table',
                name: 'lines:add-rate-table',
                component: RateTableDetails,
                props: true,
                meta: {
                  layout: 'full-page',
                  breadcrumbsLoader: 'loadNewRateTableBreadcrumbs'
                }
              },
              {
                path: 'table/:rateTableName',
                name: 'lines:update-rate-table',
                component: RateTableDetails,
                props: true,
                meta: {
                  layout: 'full-page',
                  breadcrumbsLoader: 'loadRateTableBreadcrumbs'
                }
              },
              {
                path: 'item',
                name: 'lines:add-risk-item',
                component: RiskItemManage,
                props: true,
                meta: {
                  breadcrumbsLoader: 'loadNewRiskItemBreadcrumbs'
                }
              },
              {
                path: 'item/:riskItemName',
                name: 'lines:risk-item-details',
                component: RiskItemDetails,
                props: true,
                meta: {
                  layout: 'full-page',
                  breadcrumbsLoader: 'loadRiskItemBreadcrumbs'
                }
              },
              {
                path: 'limit',
                name: 'lines:add-limit',
                component: Limit,
                props: true
              },
              {
                path: 'limit/:limitId/edit',
                name: 'lines:edit-limit',
                component: Limit,
                props: true
              },
              {
                path: 'item/:riskItemName/edit',
                name: 'lines:edit-risk-item',
                component: RiskItemManage,
                props: true,
                meta: {
                  layout: 'full-page',
                  breadcrumbsLoader: 'loadRiskItemBreadcrumbs'
                }
              }
            ],
            props: true
          },
          {
            path: 'preview',
            component: Product,
            children: PreviewRoutes,
            props: true
          },
          {
            path: 'mapping',
            component: ProductMappingWrapper,
            children: ProductMappingRoutes,
            props: true
          },
          {
            path: 'exports',
            name: 'productexports:exports',
            component: ProductExports
          }
        ],
        props: true
      }
    ],
    props: true
  }
]
