const InjuryList = () => import('@/modules/claims/views/claim/injury/InjuryList')
const InjuryDetail = () => import('@/modules/claims/views/claim/injury/InjuryDetail')

export default [
  {
    path: '',
    name: 'claims:injury-summary:injury-list',
    component: InjuryList
  },
  {
    path: `:id`,
    name: 'claims:injury-summary:injury-details',
    component: InjuryDetail
  }
]
