const Page = () => import('@/pages/quote/Page.vue')
const PageWrapper = () => import('@/pages/quote/PageWrapper.vue')
const PageGroup = () => import('@/pages/quote/PageGroup.vue')
const QuoteSearch = () => import('@/pages/quote/QuoteSearch.vue')
const QuoteCreate = () => import('@/pages/quote/QuoteCreate.vue')
const QuoteUnderwriterQueue = () => import('@/pages/quote/QuoteUnderwriterQueue.vue')
const QuoteFlow = () => import('@/pages/quote/QuoteFlow')
const RiskEditPage = () => import('@/pages/quote/RiskEditPage')
const UnderwriterReview = () => import('@/pages/quote/UnderwriterReview')

export default [
  // Since the real Quote search is integrated into the Gen2 policies search
  // We only show this Quote search page in development for developer convenience
  // It can be accessed by explicitly visiting '/quote' and does not have a navigation link
  // In production the '/quote' route shows a 404
  ...(process.env.NODE_ENV === 'development'
    ? [{ path: '', component: QuoteSearch }]
    : [{ path: '', redirect: '/' }]
  ),
  {
    path: 'create',
    name: 'quote:create-quote',
    component: QuoteCreate,
    meta: {
      layout: 'full-page--no-container-padding'
    }
  },
  {
    path: 'underwriter-queue',
    name: 'quote:underwriter-queue',
    component: QuoteUnderwriterQueue
  },
  {
    path: 'underwriter-queue/:quoteNumber',
    name: 'quote:underwriter-review',
    component: UnderwriterReview,
    props: true,
    meta: {
      layout: 'full-page--no-container-padding'
    }
  },
  {
    path: ':quoteNumber',
    name: 'quote',
    component: QuoteFlow,
    children: [
      {
        path: 'p/:pageGroupName',
        name: 'quote:page-group',
        component: PageGroup,
        children: [
          {
            path: ':pageName',
            component: PageWrapper,
            children: [
              {
                path: '',
                name: 'quote:page',
                component: Page,
                props: true
              },
              {
                path: 'risks/add',
                name: 'quote:add-risk',
                component: RiskEditPage,
                props: true
              },
              {
                path: 'risks/:riskQuoteId',
                name: 'quote:edit-risk',
                component: RiskEditPage,
                props: true
              }
            ],
            props: true
          }
        ],
        props: true
      }
    ],
    meta: {
      layout: 'full-page--no-container-padding'
    },
    props: true
  }
]
