import metaPresets from '@/router/metaPresets'

const SystemAccessPolicyList = () => import('@/pages/access/SystemAccessPolicyList')
const CustomAccessPolicyList = () => import('@/pages/access/CustomAccessPolicyList')
const AccessRoles = () => import('@/pages/access/AccessRoles')
const AccessRolesSetup = () => import('@/pages/access/AccessRolesSetup')
const AccessRolesAssignPolicies = () => import('@/pages/access/AccessRolesAssignPolicies')
const AccessRolesEdit = () => import('@/pages/access/AccessRolesEdit')
const AccessPolicyEdit = () => import('@/pages/access/AccessPolicyEdit')
const AccessPolicyAssignmentStage = () => import('./AccessPolicyAssignmentStage')
const AccessPolicyReview = () => import('./AccessPolicyReview')
const AccessRolesReview = () => import('./AccessRolesReview')
const AccessRoleSelectionPanel = () => import('@/components/access/AccessRoleSelectionPanel')

export default [
  {
    path: '/access',
    redirect: {
      name: 'access:roles'
    }
  },
  {
    path: '/access/policies-system',
    name: 'access:policies-system',
    component: SystemAccessPolicyList
  },
  {
    path: '/access/policies-custom',
    name: 'access:policies-custom',
    component: CustomAccessPolicyList
  },
  {
    path: '/access/edit/:policyId?',
    name: 'access:policy-edit',
    component: AccessPolicyEdit,
    props: true
  },
  {
    path: '/access/assign/:policyId?',
    name: 'access:policy-assign',
    component: AccessPolicyAssignmentStage,
    props: true
  },
  {
    path: '/access/review/:policyId?',
    name: 'access:policy-review',
    component: AccessPolicyReview,
    props: true
  },
  {
    path: '/access/roles',
    name: 'access:roles',
    component: AccessRoles
  },
  {
    path: '/access/roles/setup/:roleId?',
    name: 'access:roles-setup',
    component: AccessRolesSetup,
    props: true
  },
  {
    path: '/access/roles/assign-policies/:roleId?',
    name: 'access:roles-assign-policies',
    component: AccessRolesAssignPolicies,
    props: true
  },
  {
    path: '/access/roles/edit/:roleId?',
    name: 'access:roles-edit',
    component: AccessRolesEdit,
    props: true
  },
  {
    path: '/access/roles/review/:roleId?',
    name: 'access:roles-review',
    component: AccessRolesReview,
    props: true
  },
  {
    path: '/access/select-role',
    name: 'select-role',
    component: AccessRoleSelectionPanel,
    props: route => route.query,
    meta: {
      layout: 'component-only',
      ...metaPresets.ignoreAuthorization
    }
  }
]
