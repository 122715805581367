const Accounting = () => import('@/modules/claims/views/claim/claimant/exposure/Accounting')
const Details = () => import('@/modules/claims/views/claim/claimant/exposure/Details')
const InjuryDetail = () => import('@/modules/claims/views/claim/injury/InjuryDetail')
const RiskDetail = () => import('@/modules/claims/views/claim/risk/RiskDetail')
const Risk = () => import('@/modules/claims/views/claim/risk/Risk')
const AttachmentsList = () => import('@/modules/claims/views/claim/attachment/AttachmentsList')

export default [
  {
    path: `details`,
    name: 'claims:claimants:exposures:details',
    component: Details
  },
  {
    path: `accounting`,
    name: 'claims:claimants:exposures:accounting',
    component: Accounting
  },
  {
    path: `injuries/:id`,
    name: 'claims:claimants:exposures:injury-details',
    component: InjuryDetail,
    props: {showBackButton: false}
  },
  {
    path: 'risks/types/:risk_type_group_name',
    name: 'claims:claimants:exposures:risks:types',
    component: Risk
  },
  {
    path: 'risks/types/:risk_type_group_name/:id',
    name: 'claims:claimants:exposures:risks:types:risk-details',
    component: RiskDetail
  },
  {
    path: `attachments`,
    name: 'claims:exposures:attachments',
    component: AttachmentsList
  }
]
