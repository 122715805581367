const PolicyInfo = () => import('@/pages/policies/PolicyInfo')
const RenewalQueue = () => import('@/pages/policies/RenewalQueue')
import store from '@/store'

export const beforeEnterPolicyInfo = async (to, from, next) => {
  store.dispatch('policies/loadPolicyInfoPage', to.params.policyId).then(gen3 => {
    if (!gen3) {
      next(`/britecore/policies/information?policyId=${to.params.policyId}`)
    }
  }).catch(e => {
    console.error(e)
    next({ name: 'not-found', params: { '0': to.path } })
  })
  next()
}

export default [
  {
    path: '',
    redirect: '/britecore/policies/ko_policy_list'
  },
  {
    path: 'policy/:policyId',
    name: 'policies:overview',
    component: PolicyInfo,
    meta: {
      layout: 'default--no-container-padding'
    },
    beforeEnter: beforeEnterPolicyInfo
  },
  {
    path: 'renewal-queue',
    name: 'policies:renewal-queue',
    component: RenewalQueue
  }
]
