const Perils = () => import('@/modules/claims/views/admin-setup/perils/Perils')
const PaymentSummary = () => import('@/modules/claims/views/payment/PaymentSummary')
const AdminSetupModules = () => import('./AdminSetupModules')
const MockPoliciesMaintenance = () => import('./mock-policies/MockPoliciesMaintenance')
const ManualItems = () => import('./ManualItems')
const Policies = () => import('./mock-policies/Policies')
const PolicyTypes = () => import('@/modules/claims/views/admin-setup/mock-policies/PolicyTypes')
const PolicyStatus = () => import('@/modules/claims/views/admin-setup/mock-policies/PolicyStatus')
const UiConfigurations = () => import('@/modules/claims/views/admin-setup/UiConfigurations')
const Products = () => import('@/modules/claims/views/admin-setup/Products')
const Product = () => import('@/modules/claims/views/admin-setup/Product')
const ImportHistoricalClaims = () => import('@/modules/claims/views/admin-setup/ImportHistoricalClaims')
const Tags = () => import('./Tags')

export default [
  {
    path: '',
    name: 'claims:admin-setup:modules',
    component: AdminSetupModules
  },
  {
    path: 'mock-policies-maintenance',
    name: 'claims:admin-setup:mock-policies-maintenance',
    component: MockPoliciesMaintenance
  },
  {
    path: 'manual-items',
    name: 'claims:admin-setup:manual-items',
    component: ManualItems
  },
  {
    path: 'tags',
    name: 'claims:admin-setup:tags',
    component: Tags
  },
  {
    path: 'mock-policies-maintenance/policy',
    name: 'claims:admin-setup:mock-policies-maintenance:policies',
    component: Policies
  },
  {
    path: 'mock-policies-maintenance/policy-types',
    name: 'claims:admin-setup:mock-policies-maintenance:policy-types',
    component: PolicyTypes
  },
  {
    path: 'mock-policies-maintenance/policy-status',
    name: 'claims:admin-setup:mock-policies-maintenance:policy-status',
    component: PolicyStatus
  },
  {
    path: 'ui-configurations',
    name: 'claims:admin-setup:ui-configurations',
    component: UiConfigurations
  },
  {
    path: 'perils',
    name: 'claims:admin-setup:perils',
    component: Perils
  },
  {
    path: 'payment-processing',
    name: 'claims:admin-setup:payment-processing',
    component: PaymentSummary
  },
  {
    path: 'products-maintenance',
    name: 'claims:admin-setup:products-maintenance',
    component: Products
  },
  {
    path: 'products-maintenance/product-details/:id',
    name: 'claims:admin-setup:products-maintenance:product-details',
    component: Product
  },
  {
    path: 'import-historical-claims',
    name: 'claims:admin-setup:import-historical-claims',
    component: ImportHistoricalClaims
  }
]
