const ExploreTemplates = () => import('@/pages/documents/ExploreTemplates')
const DocumentsEditor = () => import('@/pages/documents/DocumentsEditor')

export default [
  {
    path: '',
    name: 'documents',
    component: ExploreTemplates,
    meta: {
      layout: 'full-page'
    }
  },
  {
    path: '/documents/editor/:modal?',
    name: 'documents-editor',
    component: DocumentsEditor
  },
  {
    path: '/documents/editor/:documentType/:documentId',
    name: 'documents-viewer',
    component: DocumentsEditor
  }
]
