const ProductMapping = () => import('@/pages/lines/product-mapping/ProductMapping')
const RiskTypeMappingWrapper = () => import('@/pages/lines/product-mapping/RiskTypeMappingWrapper')
const RiskTypeMapping = () => import('@/pages/lines/product-mapping/RiskTypeMapping')
const FieldMapping = () => import('@/pages/lines/product-mapping/FieldMapping')

export default [
  {
    path: '',
    name: 'productmapping:product-mapping',
    component: ProductMapping,
    props: true,
    children: [
      {
        path: `risk-type/:riskTypeName`,
        component: RiskTypeMappingWrapper,
        props: true,
        children: [
          {
            path: '',
            name: 'productmapping:risk-type-mapping',
            component: RiskTypeMapping
          },
          {
            path: `field/:fieldName`,
            name: 'productmapping:field-mapping',
            component: FieldMapping,
            props: true
          }
        ]
      }
    ]
  }
]
