const NotesGlobalList = () => import('@/pages/notes/NotesGlobalList')
const NotesSettings = () => import('@/pages/notes/NotesSettings')
const NoteDetails = () => import('@/pages/notes/NoteDetails')
const Gen2Claims = () => import('@/pages/notes/Gen2Claims')
const NoteTagsAdmin = () => import('@/pages/notes/NoteTagsAdmin')
const NoteTypesAdmin = () => import('@/pages/notes/NoteTypesAdmin')

export default [
  {
    path: 'gen2-claims',
    name: 'notes:gen2-claims',
    component: Gen2Claims
  },
  {
    path: 'settings',
    name: 'notes:settings',
    component: NotesSettings
  },
  {
    path: 'note-tags-admin',
    name: 'notes:note-tags-admin',
    component: NoteTagsAdmin
  },
  {
    path: 'note-types-admin',
    name: 'notes:note-types-admin',
    component: NoteTypesAdmin
  },
  {
    path: '',
    name: 'notes:global-list',
    component: NotesGlobalList,
    children: [
      {
        path: ':id',
        name: 'notes:note-details',
        component: NoteDetails,
        props: true
      }
    ]
  }
]
