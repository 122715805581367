import {ApiService} from './api'

class EavApi extends ApiService {
  constructor () {
    super('claims/eav-storage')
  }

  getAttributes () {
    return this.get(`attributes/`)
  }

  getAttribute (attributeId) {
    return this.get(`attributes/${attributeId}/`)
  }

  createAttribute (data) {
    return this.post('attributes/', data)
  }

  updateAttribute (attributeId, data) {
    return this.patch(`attributes/${attributeId}/`, data)
  }
}

export {
  EavApi
}
