import uuidRegex from 'uuid-regexp/regexp'

const Scenarios = () => import('@/pages/lines/preview/Scenarios')
const ScenarioWrapper = () => import('@/pages/lines/preview/ScenarioWrapper')
const Scenario = () => import('@/pages/lines/preview/Scenario')
const ScenarioRisk = () => import('@/pages/lines/preview/ScenarioRisk.vue')

export default [
  {
    path: '',
    name: 'linepreview:scenarios',
    component: Scenarios,
    props: true,
    meta: {
      breadcrumbsLoader: 'loadPreviewBreadcrumbs'
    },
    children: [
      {
        path: `:scenarioId(${uuidRegex.versioned.source})`,
        component: ScenarioWrapper,
        props: true,
        children: [
          {
            path: '',
            name: 'linepreview:scenario',
            component: Scenario,
            props: true,
            meta: {
              breadcrumbsLoader: 'loadPreviewBreadcrumbs'
            }
          },
          {
            path: `risk/:riskId(${uuidRegex.versioned.source})`,
            component: ScenarioRisk,
            name: 'linepreview:scenario-risk',
            props: true
          }
        ]
      }
    ]
  }
]
