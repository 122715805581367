import adminSetupRoutes from '@/modules/claims/views/admin-setup/routes'
import exposureRoutes from '@/modules/claims/views/claim/claimant/exposure/routes'
import injurySummaryRoutes from '@/modules/claims/views/claim/injury/routes'

const Accounting = () => import('@/modules/claims/views/claim/Accounting')
const LossDetail = () => import('@/modules/claims/views/claim/LossDetail')
const RiskDetail = () => import('@/modules/claims/views/claim/risk/RiskDetail')
const Dashboard = () => import('@/modules/claims/views/Dashboard')
const PartySearch = () => import('@/modules/claims/views/claim/PartySearch')
const AdminSetup = () => import('@/modules/claims/views/AdminSetup')
const Claim = () => import('@/modules/claims/views/claim/Claim')
const Exposures = () => import('@/modules/claims/views/claim/Exposures')
const AttachmentsList = () => import('@/modules/claims/views/claim/attachment/AttachmentsList')
const ClaimsAssign = () => import('@/modules/claims/views/ClaimsAssign')
const FileClaim = () => import('@/modules/claims/views/FileClaim')
const NewFnol = () => import('@/modules/claims/views/fnol/NewFnol')
const Risk = () => import('@/modules/claims/views/claim/risk/Risk')
const Exposure = () => import('@/modules/claims/views/claim/claimant/exposure/Exposure')
const ClaimLevel = () => import('@/modules/claims/views/claim/ClaimLevel')
const InjurySummary = () => import('@/modules/claims/views/claim/injury/Injury')

export default [
  {
    path: '',
    name: 'claims:dashboard',
    component: Dashboard
  },
  {
    path: 'admin-setup',
    component: AdminSetup,
    children: adminSetupRoutes
  },
  {
    path: 'assign',
    name: 'claims:assign',
    component: ClaimsAssign
  },
  {
    path: 'file-claim',
    name: 'claims:file-claim',
    component: FileClaim
  },
  {
    path: 'file-claim/new/:fnolNumber?',
    name: 'claims:file-claim:new',
    component: NewFnol,
    meta: {
      layout: 'default--no-container-padding'
    }
  },
  {
    path: ':claim_number',
    component: ClaimLevel,
    children: [
      {
        path: '',
        component: Claim,
        redirect: {name: 'claims:loss-detail'},
        children: [
          {
            path: 'exposures',
            name: 'claims:exposures',
            component: Exposures
          },
          {
            path: 'accounting',
            name: 'claims:accounting',
            component: Accounting
          },
          {
            path: 'risks/types/:risk_type_group_name',
            name: 'claims:risks:types',
            component: Risk
          },
          {
            path: 'risks/types/:risk_type_group_name/:id',
            name: 'claims:risks:types:risk-details',
            component: RiskDetail
          },
          {
            path: 'injuries',
            name: 'claims:injury-summary',
            redirect: {name: 'claims:injury-summary:injury-list'},
            component: InjurySummary,
            children: injurySummaryRoutes
          },
          {
            path: 'loss-details',
            name: 'claims:loss-detail',
            component: LossDetail
          },
          {
            path: 'parties',
            name: 'claims:parties',
            component: PartySearch
          },
          {
            path: 'attachments',
            name: 'claims:attachments',
            component: AttachmentsList
          }
        ]
      },
      {
        path: 'claimants/:claimant_number/exposures/:exposure_number',
        component: Exposure,
        redirect: {name: 'claims:claimants:exposures:details'},
        children: exposureRoutes
      }
    ]
  }
]
